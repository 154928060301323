
import { computed, defineComponent, onMounted, ref } from 'vue'
import { Employee } from '@/store/modules/EmployeeModule'
import { useStore } from 'vuex'
import DataTable from '@/components/mc-admin-datatable/DataTable.vue'
import { ActivitySessionFilter } from '@/store/modules/ActivitySessionModule'
import { MutationsActions } from '@/store/enums/StoreEnums'
import {
  getStatusBadge,
  formatListDate,
  formatListHourInterval
} from '@/core/mc-admin-helpers/activity-session'

export default defineComponent({
  name: 'upcoming-activities',
  components: {
    DataTable
  },
  setup () {
    const store = useStore()
    const employee = computed(() => store.getters.getEmployee) as unknown as Employee
    const tableHeader = ref([
      {
        name: 'NAME',
        key: 'name',
        sortable: true
      },
      {
        name: 'Start Date',
        key: 'startDate',
        sortable: true
      },
      {
        name: 'Status',
        key: 'status',
        sortable: true
      },
      {
        name: 'Participants',
        key: 'participants',
        sortable: true
      },
      {
        name: '',
        key: 'actions',
        sortable: true
      }
    ])
    const tableSearch = ref<string>('')
    const itemsPerPage = ref<number>(5)
    const selectedPage = ref<number>(1)
    const tableData = ref(computed(() => store.getters.getActivitySessions))
    const tableLoading = computed(() => store.getters.getActivitySessionRequestPending)
    const sessionCount = computed(() => store.getters.getActivitySessionsCount)
    const tablePagesCount = computed(() => Math.ceil(sessionCount.value / itemsPerPage.value))

    const onCurrentPageChange = (page) => {
      selectedPage.value = page
      const payload: ActivitySessionFilter = {
        filter: {
          limit: itemsPerPage.value,
          offset: itemsPerPage.value * (page - 1),
          status: 2,
          employeeUuid: employee.userUuid
        }
      }
      if (tableSearch.value !== '') {
        payload.filter.name = tableSearch.value
      }
      store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_SESSIONS, payload)
    }

    const onItemsPerPageChange = (rowsPerPage, page) => {
      itemsPerPage.value = rowsPerPage
      const payload: ActivitySessionFilter = {
        filter: {
          limit: rowsPerPage,
          offset: rowsPerPage * (--page - 1),
          status: 2,
          employeeUuid: employee.userUuid
        }
      }
      if (tableSearch.value !== '') {
        payload.filter.name = tableSearch.value
      }
      store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_SESSIONS, payload)
    }

    let timeoutId = 0 as any
    const onItemsSearch = () => {
      clearTimeout(timeoutId)
      const payload: ActivitySessionFilter = {
        filter: {
          name: tableSearch.value,
          limit: itemsPerPage.value,
          offset: itemsPerPage.value * (selectedPage.value - 1)
        }
      }
      const countPayload: ActivitySessionFilter = { filter: { name: tableSearch.value } }
      timeoutId = setTimeout(() => {
        store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_SESSIONS, payload)
        store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_SESSIONS_COUNT, countPayload)
      }, 500)
    }

    onMounted(() => {
      const payload: ActivitySessionFilter = {
        filter: {
          limit: itemsPerPage.value,
          status: 2,
          employeeUuid: employee.userUuid
        }
      }
      store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_SESSIONS, payload)
      const countPayload: ActivitySessionFilter = { filter: { status: 2, employeeUuid: employee.userUuid } }
      store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_SESSIONS_COUNT, countPayload)
    })

    return {
      employee,
      tableData,
      tableHeader,
      tableSearch,
      tablePagesCount,
      tableLoading,
      itemsPerPage,
      selectedPage,
      onCurrentPageChange,
      onItemsPerPageChange,
      onItemsSearch,
      formatListDate,
      formatListHourInterval,
      getStatusBadge
    }
  }
})
